<template>
  <tr
    v-if="authUserIsAdmin"
    :class="{
      'is-busy': isLoading || request.status === 'ignored',
      'text-decoration-line-through': request.status === 'ignored',
    }"
  >
    <td scope="col" class="align-middle">
      <div>{{ fullname }}</div>
      <div class="text-muted small">{{ request.email }}</div>
    </td>

    <td scope="col" class="align-middle">{{ request.oldPlanName }}</td>

    <td scope="col" class="align-middle">{{ request.newPlanName }}</td>

    <td scope="col" class="align-middle">
      <fa-icon v-if="request.status === 'approved'" :icon="['fas', 'check']"></fa-icon>
      <span v-else class="text-capitalize">{{ request.status }}</span>
    </td>

    <td scope="col" class="align-middle">
      <div>{{ request.createdAt | utcDateReadable }}</div>
      <div class="smaller text-muted">{{ request.createdAt | utcDateRelative }}</div>
    </td>

    <td scope="col" class="align-middle">
      <div v-if="request.actionedAt">
        <div>{{ request.actionedAt | utcDateReadable }}</div>
        <div class="smaller text-muted">{{ request.actionedAt | utcDateRelative }}</div>
      </div>
    </td>

    <td scope="col" class="align-middle text-end">
      <div
        v-if="isLoading"
        class="spinner-border spinner-border-sm text-secondary mx-1"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>

      <BootstrapDropdown v-if="!isLoading && request.status === 'requested'">
        <li
          @click="showPromptModal = true"
          class="dropdown-item small"
          role="button"
        >
          <fa-icon :icon="['fas', 'check']" class="me-1"></fa-icon>
          Approve
        </li>
        <li><hr class="dropdown-divider"></li>
        <li
          @click="showConfirmModal = true"
          class="dropdown-item small text-danger"
          role="button"
        >
          <fa-icon :icon="['fas', 'trash-alt']" class="me-1"></fa-icon>
          Ignore
        </li>
      </BootstrapDropdown>

      <portal to="modal">
        <transition name="modal">
          <ConfirmModal
            @confirm="ignore"
            @close="showConfirmModal = false"
            v-model="showConfirmModal"
            v-if="showConfirmModal"
          ></ConfirmModal>
        </transition>
      </portal>

      <portal to="modal">
        <transition name="modal">
          <RequestApprovalPromptModal
            @confirm="approve"
            @close="showPromptModal = false"
            v-model="showPromptModal"
            v-if="showPromptModal"
            :message="`Please enter Stripe subscription ID to confirm:`"
          ></RequestApprovalPromptModal>
        </transition>
      </portal>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex';
import { httpPost } from '../../../core/http';

export default {
  components: {
    BootstrapDropdown: () => import('@/components/bootstrap/BootstrapDropdown'),
    ConfirmModal: () => import('@/components/modal/ConfirmModal'),
    RequestApprovalPromptModal: () => import('./RequestApprovalPromptModal.vue'),
  },
  props: {
    request: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters('auth', ['authUserIsAdmin']),
    fullname() {
      const { firstName, middleName, lastName } = this.request;
      return [firstName, middleName, lastName].join(' ');
    },
  },
  methods: {
    async approve(subscriptionId) {
      try {
        this.showPromptModal = false;
        this.isLoading = true;
        const { coachingPlanRequestId, userId, newCoachingPlanId } = this.request;
        const payload = {
          requestId: coachingPlanRequestId,
          userId,
          planId: newCoachingPlanId,
          action: 'approved',
          subscriptionId,
        };
        const { data } = await httpPost('/subscription/manage/request', payload);
        this.$emit('update', data);
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isLoading = false;
      }
    },
    async ignore() {
      try {
        this.showConfirmModal = false;
        this.isLoading = true;
        const { coachingPlanRequestId, userId, newCoachingPlanId } = this.request;
        const payload = {
          requestId: coachingPlanRequestId,
          userId,
          planId: newCoachingPlanId,
          action: 'ignored',
        };
        const { data } = await httpPost('/subscription/manage/request', payload);
        this.$emit('update', data);
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isLoading = false;
      }
    },
  },
  data() {
    return {
      isLoading: false,
      showConfirmModal: false,
      showPromptModal: false,
    };
  },
};
</script>
